/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
// UI lib comoponents
import { Container } from 'react-grid-system';

// Local UI components
import Image from '../../../shared/Image';

// Redux actions and selectors

// Helpers & utils

// Style
import './index.scss';
// Assets
import flower from '../../../images/about-us/flower.svg';
import peopleIcon from '../../../images/about-us/frame-one.svg';
import skillsIcon from '../../../images/about-us/frame-two.svg';
import oppIcon from '../../../images/about-us/frame-three.svg';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function AboutFondation() {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  const { t } = useTranslation();
  const { title, description, dotation, list } = t('AboutElife', {
    returnObjects: true,
  });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */

  /* ***************************** RENDER HELPERS ***************************** */

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="about-fondation scroll-reveal">
      <div className="about-fondation__title-container">
        <img src={flower} alt="flower" />
        <h2>{title}</h2>
      </div>
      <div className="about-fondation__description-container scroll-reveal">
        <div className="Objectives">
          <p>{description}</p>
          <h6>{dotation}</h6>
          <div className="list-container">
            <article>
              <img src={peopleIcon} alt="" />
              <h5>{list[0].text}</h5>
            </article>
            <article>
              <img src={skillsIcon} alt="" />
              <h5>{list[1].text}</h5>
            </article>
            <article>
              <img src={oppIcon} alt="" />
              <h5>{list[2].text}</h5>
            </article>
          </div>
        </div>
        <div className="people-img-container">
          <Image src="people.png" alt="people" />
        </div>
      </div>
    </Container>
  );
}

AboutFondation.propTypes = {};

AboutFondation.defaultProps = {
  radius: 0,
};

export default AboutFondation;
