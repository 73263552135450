/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
// UI lib comoponents
import { Container } from 'react-grid-system';
// Style
import './index.scss';
// assets
import CEO from '../../../images/about-us/ceo.png';
import QUOTES from '../../../images/about-us/quotes.svg';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Slogan() {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // Other hooks like redux or localization ...
  const { t } = useTranslation();
  const { Title, descOne, descTwo } = t('sloganAboutUs', {
    returnObjects: true,
  });

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="slogan-about-us scroll-reveal" fluid>
      <h1>{Title}</h1>
      <Container className="slogan-about-us__text-description">
        <section className="section-one">
          <img src={CEO} alt="CEO" />
          <p>{descOne}</p>
        </section>
        <section className="section-two">
          <img src={QUOTES} alt="quotes" />
          <p>{descTwo}</p>
        </section>
      </Container>
    </Container>
  );
}

Slogan.propTypes = {};

Slogan.defaultProps = {
  radius: 0,
};

export default Slogan;
